import React, { useEffect } from "react";
import Page from "../components/Page";
import Button from "../components/Button";
import RichText from "../components/RichText";
import { v4 as uuid } from "uuid";

// Import all the functions we need
import { useSelector } from "react-redux";
import LoadingPage from "./LoadingPage";

import NavSwitch from "../components/NavSwitch";
import ProductRow from "../components/ProductRow";
import Maps from "../components/Maps";
import Table from "../components/Table";

function IndexPage() {
  // access our data so that we can display it
  const singleTypeSlug = "landing-page";
  const { data } = useSelector((state) => state.cms);
  const cmsData = data[singleTypeSlug] && data[singleTypeSlug].attributes;

  // only display the component if our data has been loaded
  if (!cmsData) return <LoadingPage />;

  return (
    <Page data-wf-page="63a4ab5fc97e6a1beb2033c6" data-wf-site="63a4ab5fc97e6aeff12033c5">
      {/* hero section */}
      <div
        className="section-2 wf-section"
        style={{ backgroundImage: `radial-gradient(circle farthest-corner at 50% 50%, rgba(0, 0, 0, 0.6), transparent), url('${cmsData?.heroBackground?.data?.attributes?.url}')` }}
      >
        <div className="container-2 w-container">
          <img src={cmsData?.logo?.data?.attributes?.url} loading="lazy" alt="" className="mainlogo" />
          {cmsData?.tagline?.split("\n")?.map((el) => {
            return (
              <div key={uuid()} className="tagline">
                <strong className="taglinetext">{el}</strong>
              </div>
            );
          })}

          <div className="div-block-3 herobtns">
            {cmsData?.buttons?.map((el) => {
              return <Button key={uuid()} data={el} className="button shadow w-button" />;
            })}
          </div>
        </div>
      </div>
      <NavSwitch />
      {cmsData?.textBlock1 && (
        <div className="textblock hero wf-section">
          <div className="w-container">
            <RichText text={cmsData?.textBlock1} />
            {cmsData?.textBlock1Button && <Button data={cmsData?.textBlock1Button} className="button shadow w-button" />}
          </div>
        </div>
      )}

      {/* products section */}
      {cmsData?.products?.length > 0 && (
        <div className="section-5 wf-section">
          <div className="container-3 w-container">
            {/* <h2 className="heading2-subtle hide">What We Do Best</h2> */}
            {cmsData?.products?.map((el, i) => {
              return <ProductRow key={uuid()} data={el?.product?.data?.attributes} i={i} />;
            })}
          </div>
        </div>
      )}

      {/* process */}
      {/* <div className="centered wf-section">{cmsData?.processImg && <img src={cmsData?.processImg?.data?.attributes?.url} loading="lazy" alt="" className="processimg" />}</div> */}

      {/* Text sections */}
      {cmsData?.textBlock2 && (
        <div className="textblock wf-section">
          <div className="w-container">
            <RichText text={cmsData?.textBlock2} />
            {cmsData?.textBlock2Button && <Button data={cmsData?.textBlock2Button} className="button shadow w-button" />}
          </div>
        </div>
      )}
      {cmsData?.textBlock3 && (
        <div className="textblock wf-section">
          <div className="w-container">
            <RichText text={cmsData?.textBlock3} />
            {cmsData?.textBlock3Button && <Button data={cmsData?.textBlock3Button} className="button shadow w-button" />}
          </div>
        </div>
      )}

      {/* maps section */}
      <div className="section-4 wf-section">
        <div className="container-6 w-container">
          <Maps data={cmsData?.locations} />
        </div>
        <div className="container-4 w-container">
          <h2 className="heading2-subtle">{cmsData?.postMapsHeading}</h2>
          {cmsData?.postMapsButton && (
            <a href={cmsData?.postMapsButton?.link} target="_blank" className="button shadow contractor w-button">
              {cmsData?.postMapsButton?.text}
            </a>
          )}
        </div>
      </div>

      {/* widget section */}
      <div className="section-3 wf-section">
        <h2 className="heading2-subtle">{cmsData?.reviewsHeading}</h2>
        <div className="html-embed w-embed w-script">
          <div className={cmsData?.reviewsWidget || "elfsight-app-409cef59-47c8-48ea-8d6d-00f7c2b57bff"} />
        </div>
        <div className="cover"></div>
      </div>

      {/* awards section */}
      <div className="awards wf-section">
        <div className="div-block-9">
          {cmsData?.awards?.map((el) => {
            return (
              <a key={uuid()} target="_blank" href={el.link} className="w-inline-block">
                <img src={el?.image?.data?.attributes?.url} loading="lazy" alt="" className="awardimage" />
              </a>
            );
          })}
        </div>
      </div>
    </Page>
  );
}

export default IndexPage;
