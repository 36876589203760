import React, { useEffect, useState } from "react";
import CrossfadeImage from "react-crossfade-image";
const ProductSlider = ({ data }) => {
  const [active, setActive] = useState(0);
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    let interval = setInterval(() => {
      setActive((prevActive) => (prevActive + 1) % data.length);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="slider-2 shadow " onMouseEnter={() => setHovered(active)} onMouseLeave={() => setHovered(null)}>
      {hovered ? (
        <img src={data[hovered].attributes.url} style={{ width: "100%", height: 400, objectFit: "cover", maxWidth: "none", maxHeight: "none" }} />
      ) : (
        <CrossfadeImage src={data[active].attributes.url} style={{ width: "100%", height: 400, objectFit: "cover", maxWidth: "none", maxHeight: "none" }} />
      )}
    </div>
  );
};

export default ProductSlider;
