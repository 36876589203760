import React from "react";
import ProductSlider from "./ProductSlider";

const ProductRow = ({ data, i }) => {
  return (
    <div className={`productrow ${i % 2 !== 0 && "odd"}`}>
      <div className="servicetextdiv">
        <div
          className="servicetext orange"
          style={{
            textAlign: "left",
            lineHeight: "1.25",
          }}
        >
          {data?.title}
        </div>
        <p
          style={{
            fontSize: "16px",
            lineHeight: "24px",
            textAlign: "justify",
            paddingTop: "16px",
          }}
        >
          {data?.description}
        </p>
        <a
          href={"/products/" + data?.slug}
          className="button shadow w-button"
          style={{
            flex: "none",
          }}
        >
          Learn More
        </a>
      </div>
      <ProductSlider data={data?.images?.data} />
    </div>
  );
};

export default ProductRow;
